import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash";

const initialState = {
  configs: undefined
}

export const vendorConfigs = createSlice({
  name: 'vendorConfigs',
  initialState,
  reducers: {
    setVendorConfigs: (state, action) => {
      state.configs = _.groupBy(action.payload, (v) => v.vendor)
    },
  }
})

export const { setVendorConfigs } = vendorConfigs.actions

export default vendorConfigs.reducer