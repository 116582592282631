import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    list: []
}

export const locations = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setLocations: (state, action) => {
            state.list = action.payload
        }
    }
})

export const { setLocations } = locations.actions

export default locations.reducer