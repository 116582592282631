import { createSlice } from "@reduxjs/toolkit"

const locationsView = "locationsView"
const plansView = "plansView"

const initialState = {
    view: locationsView
}

export const hostedVpn = createSlice({
    name: 'hostedVpn',
    initialState,
    reducers: {
        setLocationsView: (state) => {
            state.view = locationsView
        },
        setPlansView: (state) => {
            state.view = plansView
        }
    }
})

export const { setLocationsView, setPlansView } = hostedVpn.actions

export default hostedVpn.reducer