import { configureStore } from "@reduxjs/toolkit"
import { hostedVpn } from "./hostedVpnSlice"
import { profile } from "./profileSlice"
import { locations } from "./locationsSlice"
import { stripe } from "./stripeSlice"
import { vendorConfigs } from "./vendorConfigSlice"

const createStore = () => configureStore({
    reducer: {
        hostedVpn: hostedVpn.reducer,
        profile: profile.reducer,
        locations: locations.reducer,
        stripe: stripe.reducer,
        vendorConfigs: vendorConfigs.reducer,
    }
})

export default createStore