import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userName: "",
    email: ""
}

export const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        }
    }
})

export const { setUserName, setEmail } = profile.actions

export default profile.reducer