import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    priceTableId: "",
    publishableKey: "",
    customerPortal: ""
}

export const stripe = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        setPriceTableId: (state, action) => {
            state.priceTableId = action.payload
        },
        setPublishableKey: (state, action) => {
            state.publishableKey = action.payload
        },
        setCustomerPortal: (state, action) => {
            state.customerPortal = action.payload
        }
    }
})

export const { setPriceTableId, setPublishableKey, setCustomerPortal } = stripe.actions

export default stripe.reducer