/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "./src/styles/reset.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./src/styles/global.css"
import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider